import { render, staticRenderFns } from "./businessDistrictList.vue?vue&type=template&id=7a552950&scoped=true"
import script from "./businessDistrictList.vue?vue&type=script&lang=js"
export * from "./businessDistrictList.vue?vue&type=script&lang=js"
import style0 from "./businessDistrictList.vue?vue&type=style&index=0&id=7a552950&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a552950",
  null
  
)

export default component.exports